.transformer__possibility{
   display: flex;
   flex-direction: row;
}

.transformer__posssibility-image{
   flex: 1;
   display: flex;
   justify-content: flex-start;
   align-items: center;

   margin-right: 2rem;
}

.transformer__posssibility-image img{
   width: 100%;
   height: 100%;
}

.transformer__posssibility-content{
   flex: 1;
   display: flex;
   flex-direction: column;
   justify-content: flex-end;
   align-items: flex-start;
}

.transformer__posssibility-content h4{
  
   font-weight: 500;
   font-size: 16px;
   line-height: 30px;
   color: #71E5FF;
   margin-bottom: 2rem;
   font-family: var(--font-family);
}

.transformer__posssibility-content h4:last-child{
   color: #FF8A71;
}


.transformer__posssibility-content h1{
   font-weight: 800;
   font-size: 34px;
   line-height: 45px;
   margin-bottom: 2rem;
   font-family: var(--font-family);
}


.transformer__posssibility-content p{
   font-weight: normal;
   font-size: 16px;
   line-height: 30px;
   margin-bottom: 2rem;
   color: var(--color-text);
   font-family: var(--font-family);
}


@media screen and (max-width:950px){
   .transformer__possibility{
      flex-direction: column;
   }
   .transformer__posssibility-image{
      margin: 1rem 0;
   }
   .transformer__posssibility-content{
      margin-top: 2rem;
   }
   
}