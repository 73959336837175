/* PrivacyPolicy.css */

.privacy-policy {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    background-color: #001F3F; /* Dark blue background */
    color: #FFFFFF; /* White text color */
  }
  
  .privacy-policy h1 {
    font-size: 28px;
    color: #FFD700; /* Golden color for headings */
  }
  
  .privacy-policy h2 {
    font-size: 24px;
    color: #FF5733; /* Coral color for subheadings */
  }
  
  .privacy-policy p {
    margin-bottom: 20px;
    line-height: 1.5;
  }
  
  /* Add additional styles as needed */
  