.transformer__brand{
   display: flex;
   flex-wrap: wrap;
   justify-content: center;
   align-items: center;
}

.transformer__brand figure{
   flex: 1;
   max-width: 150px;
   min-width: 120px;
   margin: 1rem;

   display: flex;
   justify-content: center;
   align-items: center;
}