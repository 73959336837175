.transformer__features{
   width: 100%;
   display: flex;
   justify-content: space-between;
   flex-direction: row;
}
.transformer__features-heading{
   flex: 1;
   display: flex;
   flex-direction: column;
   justify-content: flex-start;
   align-items: flex-start;

   text-align: left;
   margin-right: 5rem;
}

.transformer__features-heading h1{

   font-style: normal;
   font-weight: 800;
   font-size: 34px;
   line-height: 45px;

   font-family: var(--font-family);
}

.transformer__features-heading p{

   font-style: normal;
   font-weight: 500;
   font-size: 16px;
   line-height: 30px;  
   color: #FF8A71;

   font-family: var(--font-family);
}

.transformer__features-container{
   flex: 1.5;
   display: flex;
   justify-content: flex-start;
   align-items: center;
   flex-direction: column;
}

@media screen and (max-width:990px) {
   .transformer__features{
      flex-direction: column;
   }
   .transformer__features-heading{
      margin: 0 0 2rem 0;
   }
}

@media screen and (max-width:550px) {
   .transformer__features-heading h1{
      font-size: 28px;
      line-height: 38px;
   }
}