.transformer__navbar{
   display: flex;
   justify-content: space-between;
   align-items: center;
   padding: 2rem 6rem;
}

.transformer__navbar-links{
   display: flex;
   flex: 1;
   justify-content: flex-start;
   align-items: center;
}
.transformer__navbar-links_logo{
   margin-right: 2rem;
}

.transformer__navbar-links_logo img{
   width: 100%;
   height: 50px;
}
.transformer__navbar-links_container{
   display: flex;
   flex-direction: row;
}
.transformer__navbar-sign{
   display: flex;
   justify-content: flex-end;
   align-items: center;
}
.transformer__navbar-links_container p,
.transformer__navbar-sign p,
.transformer__navbar-menu_container p{

   color: #fff;
   font-weight: 500;
   font-size: 18px;
   line-height: 25px;
   margin: 0 1rem;
   cursor: pointer;
   text-transform: capitalize;
   font-family: var(--font-family);

}

.transformer__navbar-sign button,
.transformer__navbar-menu_container button {

   padding: 0.5rem 1rem;
   background: #ff4820;
   color: #fff;
   font-weight: 500;
   font-size: 18px;
   line-height: 25px;
   border-radius: 5px;
   outline: none;
   border: 0;
   cursor: pointer;
   font-family: var(--font-family);

}
.transformer__navbar-menu{
   margin-left: 1rem;
   display: none;
   position: relative;
}

.transformer__navbar-menu svg{
   cursor: pointer;
}

.transformer__navbar-menu_container{
   display: flex;
   justify-content: flex-end;
   align-items: flex-end;
   flex-direction: column;
   text-align: end;
   padding: 2rem;
   position: absolute;
   top: 40px;
   right: 0;
   margin-top: 1rem;
   min-width: 210px;
   border-radius: 5px;
   background: var(--color-footer);
   -webkit-border-radius: 5px;
   -moz-border-radius: 5px;
   -ms-border-radius: 5px;
   -o-border-radius: 5px;
   box-shadow: 0 0 5 rgba(0,0,0,0.2);
}

.transformer__navbar-menu_container p{
   margin: 1rem 0;
}

.transformer__navbar-menu_container-links-sign{
   display: none;
}


@media screen and (max-width:1050px){
   .transformer__navbar-links_container{
      display: none;
   }

   .transformer__navbar-menu{
      display: flex;
   }
   
}


@media screen and (max-width:700px){
   .transformer__navbar{
      padding: 2rem 4rem;
   }
   
}


@media screen and (max-width:550px){
   .transformer__navbar{
      padding: 2rem;
   }
   .transformer__navbar-sign{
      display: none;
   }
   .transformer_navbar-menu_container{
      top: 20px;
   }
   .transformer__navbar-menu_container-links-sign{
      display: block;
   }
}